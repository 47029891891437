import { deleteRequest, getFileRequest, getRequest, postRequest, putRequest } from 'src/utils/http';

import { demandsController } from './consts';

export const getDemandById = async (id, query) => getRequest(`${demandsController}/${id}`, query);

export const listDemands = async (params) => getRequest(`${demandsController}`, params);

export const createDemand = async (demand) => postRequest(`${demandsController}`, demand);

export const duplicateDemand = async (demand, data, all = false) =>
  postRequest(`${demandsController}/${demand}/duplicate?all=`+all, data);

export const updateDemand = async (demand, data, params) =>
  putRequest(`${demandsController}/${demand}`, data, params);

export const updateDemandDraft = async (demand, data) =>
  putRequest(`${demandsController}/${demand}/draft`, data);

export const updateDemandActive = async (demand, data) =>
  putRequest(`${demandsController}/${demand}/active`, data);

export const updateDemandContinue = async (demand, data, params) =>
  putRequest(`${demandsController}/${demand}/continue`, data, params);

export const updateDemandChangeDates = async (demand, data, params) =>
  putRequest(`${demandsController}/${demand}/dates`, data, params);

export const updateDemandCycles = async (demand, data, params) =>
  putRequest(`${demandsController}/${demand}/cycles`, data, params);

  export const updateDemandAttachments = async (demand, data) =>
  putRequest(`${demandsController}/${demand}/attachments`, data);

export const updateDemandSend = async (demand, data) =>
  putRequest(`${demandsController}/${demand}/send`, data);

export const deleteDemand = async (demand, params) =>
  deleteRequest(`${demandsController}/${demand}/draft`, params);

export const cancelDemand = async (demand) => putRequest(`${demandsController}/${demand}/cancel`);

export const finishDemand = async (demand) => putRequest(`${demandsController}/${demand}/finish`);

export const getPdfDemandByVersionId = async (demandId, versionId, params) =>
  getFileRequest(
    `${demandsController}/${demandId}/versions/${versionId}/pdf`,
    'application/pdf',
    params,
  );

export const rollbackDemand = async (demandId) =>
  postRequest(`${demandsController}/${demandId}/rollback`, {});
