import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Alert, AlertTitle, Autocomplete } from '@material-ui/lab';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ROLES, ROLES_ID } from 'src/constants/roles';
import { useGetRoles } from 'src/hooks/roles';
import {
  MANAGE_DEMAND_ACTIONS,
  MANAGE_DEMAND_FINAL_REASONS,
  MANAGE_DEMAND_REASONS,
} from 'src/constants/demands';
import { useSelector } from 'react-redux';
import {
  getRoleCurrentUser,
  isManager as isManagerSelector,
  isRepresentive as isRepresentiveSelector,
} from 'src/store/selectors/user';
import FilesField from 'src/components/FilesField';
import * as Yup from 'yup';
import { useListUsers } from 'src/hooks/users';
import pickBy from 'lodash/pickBy';

const useStyles = makeStyles((theme) => ({
  actions: {
    justifyContent: 'center',
  },
  btn_container: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%',
  },
  btn: {
    color: '#fff',
  },
  btn_no_selected: {
    backgroundColor: '#fff',
  },
  autorizar_btn: {
    backgroundColor: '#00b894',
  },
  autorizar_btn_no_selected: {
    color: '#00b894',
    border: '1px solid #00b894',
    '&:hover': {
      backgroundColor: '#00b894',
      color: '#fff',
    },
  },
  denegar_btn: {
    backgroundColor: '#d63031',
  },
  denegar_btn_no_selected: {
    color: '#d63031',
    border: '1px solid #d63031',
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#d63031',
      color: '#fff',
    },
  },
  info_btn: {
    backgroundColor: '#0984e3',
  },
  info_btn_no_selected: {
    color: '#0984e3',
    border: '1px solid #0984e3',
    '&:hover': {
      backgroundColor: '#0984e3',
      color: '#fff',
    },
  },
  evaluar_btn: {
    backgroundColor: '#74b9ff',
  },
  evaluar_btn_no_selected: {
    color: '#74b9ff',
    border: '1px solid #74b9ff',
    '&:hover': {
      backgroundColor: '#74b9ff',
      color: '#fff',
    },
  },
}));


const stylesPopUp = makeStyles((theme) =>({
  actions: {
    fontSize: '16px',
    color: '#f44336',
    textAlign: 'center',
    backgroundColor: 'lightyellow'
  },
}));

// toRoles options depending on user role
const ROLES_OPTIONS = {
  [ROLES.MANAGER]: [ROLES.DOCTOR, ROLES.CONSULTANT, ROLES.REPRESENTATIVE],
  [ROLES.CONSULTANT]: [ROLES.MANAGER],
  [ROLES.REPRESENTATIVE]: [ROLES.DOCTOR, ROLES.MANAGER],
};

const ACTIONS_OPTIONS = {
  [ROLES.MANAGER]: {
    [ROLES.DOCTOR]: [MANAGE_DEMAND_ACTIONS.REQUEST_MISSING_INFO],
    [ROLES.CONSULTANT]: [MANAGE_DEMAND_ACTIONS.REQUEST_AUTHORIZATION],
    [ROLES.REPRESENTATIVE]: [MANAGE_DEMAND_ACTIONS.ACCEPT, MANAGE_DEMAND_ACTIONS.DENY],
  },
  [ROLES.CONSULTANT]: {
    [ROLES.MANAGER]: [
      MANAGE_DEMAND_ACTIONS.PROPOSE_AUTHORIZATION,
      MANAGE_DEMAND_ACTIONS.PROPOSE_DENIAL,
    ],
  },
  [ROLES.REPRESENTATIVE]: {
    [ROLES.DOCTOR]: [MANAGE_DEMAND_ACTIONS.ACCEPT, MANAGE_DEMAND_ACTIONS.DENY],
    [ROLES.MANAGER]: [MANAGE_DEMAND_ACTIONS.REQUEST_MISSING_INFO],
  },
};


const ManageDemandForm = ({ demand, initialValues, handleUpdateDemand, closeDialog }) => {
  const userRole = useSelector(getRoleCurrentUser);
  const lastRole = get(demand, 'versions[0].histories[0].fromRoleId');
  const isManager = useSelector(isManagerSelector);
  const isRepresentive = useSelector(isRepresentiveSelector);
  const classes = useStyles();
  const classesPopUp = stylesPopUp();
  const companyName = demand.company?.name;
  const { t } = useTranslation(['demands', 'roles', 'rolesNames', 'users', 'common']);
  const [buttonSelected, setButtonSelected] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const { data: roles, isError: isErrorRoles } = useGetRoles({
    all: true,
  });
  const {
    isLoading: isLoadingConsultants,
    data: consultants,
    isError: isErrorConsultants,
  } = useListUsers(
    {
      all: true,
      roleId: roles?.data.rows?.find(({ permissions }) => permissions === ROLES.CONSULTANT)?.id,
    },
    !!roles.data && isManager,
  );

  const validationSchema = Yup.object().shape({
    toRoleId: Yup.object().nullable().required(t('errors.toRole.required')),
    toUserId: Yup.object()
      .nullable()
      .when('toRoleId', (role, schema) =>
        isManager && role?.permissions === ROLES.CONSULTANT
          ? schema.required(t('errors.toUser.required'))
          : schema,
      ),
    action: Yup.string().required(t('errors.action.required')),
    decisionReason: Yup.string()
      .nullable()
      .when('toRoleId', (role, schema) =>
        isManager && role?.permissions === ROLES.REPRESENTATIVE
          ? schema.required(t('errors.decision_reason.required'))
          : schema,
      ),
    comment: Yup.string().required(t('errors.comment.required')),
    privateComment: Yup.string(),
    attachments: Yup.array().of(
      Yup.object().shape({
        descripcion: Yup.string().required(t('errors.attachments_values.descripcion.required')),
        archivo: Yup.string().required(t('errors.attachments_values.archivo.required')),
      }),
    ),
    finalReason: Yup.string()
      .nullable()
      .when('toRoleId', (role, schema) =>
        isRepresentive && role?.permissions === ROLES.DOCTOR
          ? schema.required(t('errors.final_decision.required'))
          : schema,
      ),
  });

  const getDecisionReasons = () => {
    const reasonsConst = Object.keys(MANAGE_DEMAND_REASONS).map(
      (reason) => MANAGE_DEMAND_REASONS[reason],
    );
    return reasonsConst;
  };

  const getFinalReasons = () => {
    const reasonsConst = Object.keys(MANAGE_DEMAND_FINAL_REASONS).map(
      (reason) => MANAGE_DEMAND_FINAL_REASONS[reason],
    );
    return reasonsConst;
  };

  const fastManage = (role, action, setFieldValue) => {
    setButtonSelected(action);
    setFieldValue(
      'toRoleId',
      roles.data.rows.find(({ permissions }) => permissions === role),
    );
    setFieldValue('action', action);
  };

  const onSubmit = (values) => {
    const data = pickBy(values);

    if (data.toUserId) {
      data.toUserId = data.toUserId.id;
    }
    data.decisionReason = values.decisionReason;
    if(data.toRoleId.id===4 && isAcceptedDecisionAAOO(data.decisionReason) && data.action==='DENY'){
      if (!window.confirm("Está rechazando un tratamiento que ha sido propuesto para AUTORIZACIÓN por parte de ORGOA. ¿Desea continuar?")){
        return false
      }
    }else if (data.toRoleId.id===4 && !isAcceptedDecisionAAOO(data.decisionReason) && data.action==='ACCEPT'){
      if (!window.confirm("Está autorizando un tratamiento que ha sido propuesto para DENEGACIÓN por parte de ORGOA. ¿Desea continuar?")){
        return false
      }
    }
    
    return handleUpdateDemand(data);
  };
  function isAcceptedDecisionAAOO(txt){
    if(txt == MANAGE_DEMAND_REASONS['AUTORIZADO'])
      return true
    if(txt == MANAGE_DEMAND_REASONS['AUTORIZADONOCOSTEEFICIENTE'])
      return true
    if(txt == MANAGE_DEMAND_REASONS['USOFUERADEINDICACIONAUTORIZACION'])
      return true
    if(txt == MANAGE_DEMAND_REASONS['NOCUBIERTOAUTORIZACION'])
      return true

    return false
  }
  function isAcceptedDecisionAseg(txt){
    if(txt == MANAGE_DEMAND_FINAL_REASONS['SOLICITUDAUTORIZADA'])
      return true
    if(txt == MANAGE_DEMAND_FINAL_REASONS['AUTORIZADAEXCEPCIONALRECOMENDADO'])
      return true
    if(txt == MANAGE_DEMAND_FINAL_REASONS['AUTORIZADAEXCEPCIONALNORECOMENDADO'])
      return true
    if(txt == MANAGE_DEMAND_FINAL_REASONS['AUTORIZADAFARMACONOCUBIERTO'])
      return true

    return false
  } 

  useEffect(() => {
    if (isErrorRoles) {
      enqueueSnackbar(t('roles:modals.error.title_get_roles'), { variant: 'error' });
    }
  }, [enqueueSnackbar, isErrorRoles, t]);

  useEffect(() => {
    if (isErrorConsultants) {
      enqueueSnackbar(t('users:modals.error.title_list_users'), { variant: 'error' });
    }
  }, [enqueueSnackbar, isErrorConsultants, t]);

  function GridTrue() {
    return   <Grid>
              <p className={classesPopUp.actions}>
                SE DEBE VERIFICAR SI SE HA INDICADO LA FECHA DE LA PÓLIZA DEL ASEGURADO
              </p>            
            </Grid>
  }
  
  function GridFalse() {
    return   <Grid> </Grid>
  }
  
  function Company(props) {
    const isAgrupacio = companyName;
    if (isAgrupacio === 'Agrupació' && isManager) {
      return <GridTrue/>;
    }
    return <GridFalse/>;
  }
  function hasValidationStep(company){
    if(company?.validationStep){
      return true;
    }
    return false;
  }

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({
        errors,
        handleBlur,
        setFieldTouched,
        handleChange,
        setFieldValue,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <Box p={1}>
              <Grid container spacing={3}>
                {demand.versions[0]?.changeDatesReason && (
                  <Grid item xs={12} mb={4}>
                    <Alert severity="info">
                      <AlertTitle>{t('labels.changed_dates_alert')}</AlertTitle>
                      {demand.versions[0]?.changeDatesReason}
                    </Alert>
                  </Grid>
                )}

                {isRepresentive && (
                  <Grid item xs={12} mb={4}>
                    <Typography align="center">
                      {demand.decisionReason && 
                      t('labels.decision_reason', { decisionReason: demand.decisionReason })}
                    </Typography>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <Box className={classes.btn_container}>
                    {userRole === ROLES.CONSULTANT && (
                      <>
                        <Button
                          variant="contained"
                          className={
                            buttonSelected === MANAGE_DEMAND_ACTIONS.PROPOSE_AUTHORIZATION
                              ? `${classes.btn} ${classes.autorizar_btn}`
                              : `${classes.btn_no_selected} ${classes.autorizar_btn_no_selected}`
                          }
                          onClick={() =>
                            fastManage(
                              ROLES.MANAGER,
                              MANAGE_DEMAND_ACTIONS.PROPOSE_AUTHORIZATION,
                              setFieldValue,
                            )
                          }
                        >
                          {t('buttons.propose_authorization')}
                        </Button>
                        <Button
                          variant="contained"
                          className={
                            buttonSelected === MANAGE_DEMAND_ACTIONS.PROPOSE_DENIAL
                              ? `${classes.btn} ${classes.denegar_btn}`
                              : `${classes.btn_no_selected} ${classes.denegar_btn_no_selected}`
                          }
                          onClick={() =>
                            fastManage(
                              ROLES.MANAGER,
                              MANAGE_DEMAND_ACTIONS.PROPOSE_DENIAL,
                              setFieldValue,
                            )
                          }
                        >
                          {t('buttons.propose_denial')}
                        </Button>
                      </>
                    )}

                    {(userRole === ROLES.MANAGER || userRole === ROLES.REPRESENTATIVE) && (
                      <>
                        <Button
                          variant="contained"
                          className={
                            buttonSelected === MANAGE_DEMAND_ACTIONS.ACCEPT
                              ? `${classes.btn} ${classes.autorizar_btn}`
                              : `${classes.btn_no_selected} ${classes.autorizar_btn_no_selected}`
                          }
                          onClick={() =>
                            fastManage(
                              userRole === ROLES.MANAGER ? ROLES.REPRESENTATIVE : hasValidationStep(demand.company) ?  ROLES.MANAGER : ROLES.DOCTOR,
                              MANAGE_DEMAND_ACTIONS.ACCEPT,
                              setFieldValue,
                            )
                          }
                        >
                          {t('buttons.authorize_demand')}
                        </Button>
                        <Button
                          variant="contained"
                          className={
                            buttonSelected === MANAGE_DEMAND_ACTIONS.DENY
                              ? `${classes.btn} ${classes.denegar_btn}`
                              : `${classes.btn_no_selected} ${classes.denegar_btn_no_selected}`
                          }
                          onClick={() =>
                            fastManage(
                              userRole === ROLES.MANAGER ? ROLES.REPRESENTATIVE : ROLES.DOCTOR,
                              MANAGE_DEMAND_ACTIONS.DENY,
                              setFieldValue,
                            )
                          }
                        >
                          {t('buttons.deny_demand')}
                        </Button>
                        </>
                    )}
                    {(userRole === ROLES.MANAGER || (userRole === ROLES.REPRESENTATIVE && lastRole === ROLES_ID.MANAGER)) && (
                      <>
                        <Button
                          variant="contained"
                          className={
                            buttonSelected === MANAGE_DEMAND_ACTIONS.REQUEST_MISSING_INFO
                              ? `${classes.btn} ${classes.info_btn}`
                              : `${classes.btn_no_selected} ${classes.info_btn_no_selected}`
                          }
                          onClick={() =>
                            fastManage(
                              userRole === ROLES.MANAGER ? ROLES.DOCTOR : ROLES.MANAGER,
                              MANAGE_DEMAND_ACTIONS.REQUEST_MISSING_INFO,
                              setFieldValue,
                            )
                          }
                        >
                          {t('buttons.more_info_demand')}
                        </Button>
                      </>
                    )}

                    {userRole === ROLES.MANAGER && (
                      <Button
                        variant="contained"
                        className={
                          buttonSelected === MANAGE_DEMAND_ACTIONS.REQUEST_AUTHORIZATION
                            ? `${classes.btn} ${classes.evaluar_btn}`
                            : `${classes.btn_no_selected} ${classes.evaluar_btn_no_selected}`
                        }
                        onClick={() =>
                          fastManage(
                            ROLES.CONSULTANT,
                            MANAGE_DEMAND_ACTIONS.REQUEST_AUTHORIZATION,
                            setFieldValue,
                          )
                        }
                      >
                        {t('buttons.evaluate_demand')}
                      </Button>
                    )}
                  </Box>
                </Grid>

                {isManager && values.toRoleId?.permissions === ROLES.CONSULTANT && (
                  <Grid item xs={12}>
                    <Autocomplete
                      openOnFocus={true}
                      selectOnFocus={false}
                      options={consultants?.data.rows || []}
                      getOptionLabel={(option) => option.name}
                      getOptionSelected={(option, value) => option.id === value.id}
                      loading={isLoadingConsultants}
                      name="toUserId"
                      value={values.toUserId}
                      onChange={(e, value) => setFieldValue('toUserId', value)}
                      onBlur={() => setFieldTouched('toUserId', true)}
                      disabled={isSubmitting}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t('fields.select_toUser')}
                          variant="outlined"
                          error={Boolean(touched.toUserId && errors.toUserId)}
                          helperText={touched.toUserId && errors.toUserId}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {isLoadingConsultants ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  </Grid>
                )}
                {isManager && (
                  <Grid item xs={12}>
                    <Autocomplete
                      openOnFocus={true}
                      selectOnFocus={false}
                      options={getDecisionReasons()}
                      getOptionLabel={(option) => option}
                      getOptionSelected={(option, value) => option === value}
                      name="decisionReason"
                      value={values.decisionReason}
                      onChange={(e, value) => setFieldValue('decisionReason', value)}
                      onBlur={() => setFieldTouched('decisionReason', true)}
                      disabled={isSubmitting}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={Boolean(touched.decisionReason && errors.decisionReason)}
                          helperText={touched.decisionReason && errors.decisionReason}
                          label={t('fields.decision_reason')}
                          variant="outlined"
                          inputProps={{
                            ...params.inputProps,
                            style: { cursor: 'pointer' },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            readOnly: true,
                            endAdornment: <>{params.InputProps.endAdornment}</>,
                          }}
                        />
                      )}
                    ></Autocomplete>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    error={Boolean(touched.comment && errors.comment)}
                    helperText={touched.comment && errors.comment}
                    label={t('fields.comment')}
                    name="comment"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={isSubmitting}
                    value={values.comment}
                    variant="outlined"
                  />
                </Grid>
                {((isManager && values.toRoleId?.permissions === ROLES.REPRESENTATIVE) ||
                  (isRepresentive && values.toRoleId?.permissions === ROLES.MANAGER)) && (
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      error={Boolean(touched.privateComment && errors.privateComment)}
                      helperText={touched.privateComment && errors.privateComment}
                      label={t('fields.privateComment')}
                      name="privateComment"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      disabled={isSubmitting}
                      value={values.privateComment}
                      variant="outlined"
                    />
                  </Grid>
                )}
                {isRepresentive && values.toRoleId?.permissions === ROLES.DOCTOR && (
                  <Grid item xs={12}>
                    <Autocomplete
                      openOnFocus={true}
                      selectOnFocus={false}
                      options={getFinalReasons()}
                      getOptionLabel={(option) => option}
                      getOptionSelected={(option, value) => option === value}
                      name="finalReason"
                      value={values.finalReason}
                      onChange={(e, value) => setFieldValue('finalReason', value)}
                      onBlur={() => setFieldTouched('finalReason', true)}
                      disabled={isSubmitting}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={Boolean(touched.finalReason && errors.finalReason)}
                          helperText={touched.finalReason && errors.finalReason}
                          label={t('fields.final_reason')}
                          variant="outlined"
                          inputProps={{
                            ...params.inputProps,
                            style: { cursor: 'pointer' },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            readOnly: true,
                            endAdornment: <>{params.InputProps.endAdornment}</>,
                          }}
                        />
                      )}
                    ></Autocomplete>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <FilesField
                    field="attachments"
                    values={values}
                    errors={errors}
                    touched={touched}
                    setFieldTouched={setFieldTouched}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    disabled={isSubmitting}
                  />
                </Grid>
              </Grid>
            </Box>
          </DialogContent>

          <Company isAgrupacio={false} />
          
          <DialogActions className={classes.actions}>
            <Button variant="contained" onClick={closeDialog}>
              {t('common:buttons.cancel')}
            </Button>
            <Button color="primary" variant="contained" onClick={handleSubmit}>
              {t('buttons.update_mange_demand')}
            </Button>
          </DialogActions>
        </form>
      )}
    </Formik>
  );
};

ManageDemandForm.propTypes = {
  demand: PropTypes.object,
  initialValues: PropTypes.object,
  handleUpdateDemand: PropTypes.func,
  closeDialog: PropTypes.func,
};

export default ManageDemandForm;
