import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { format, differenceInBusinessDays } from 'date-fns';
import PropTypes from 'prop-types';
import { FileText as DemandsIcon } from 'react-feather';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSelector } from 'react-redux';
import DemandsActions from 'src/components/Demands/DemandsActions';
import EmptyList from 'src/components/EmptyList';
import TableSortCell from 'src/components/TableSortCell';
import {
  isAdmin as isAdminSelector,
  isSuperAdmin as isSuperAdminSelector,
} from 'src/store/selectors/user';
import { getDemandStatus } from 'src/utils/models/transforms/demand';
import { getLocationTranslation } from '../../../../../utils/demands';
import { STATUS_COLORS } from '../../../../../constants/statusColors';

const useStyles = makeStyles((theme) => ({
  table_list: {
    '& th': {
      padding: '5px',
    },
    '& td': {
      padding: '5px',
      color: theme.palette.tertiary.main,
      '& a,button': {
        padding: '0px 5px',
      },
    },
  },
  status: {
    padding: 3,
    borderRadius: 5,
  },
  draft: {
    backgroundColor: STATUS_COLORS.DRAFT,
  },
  active: {
    backgroundColor: STATUS_COLORS.ACTIVE,
  },
  sended: {
    backgroundColor: STATUS_COLORS.FINISHED,
  },
  canceled: {
    backgroundColor: STATUS_COLORS.CANCELED,
  },
  pending_analysis: {
    backgroundColor: STATUS_COLORS.PENDING, 
  },
  pending_missing_info: {
    backgroundColor: STATUS_COLORS.PENDING,
  },
  pending_company_accept: {
    backgroundColor: STATUS_COLORS.PENDING,
  },
  pending_company_deny: {
    backgroundColor: STATUS_COLORS.PENDING,
  },
  pending_evaluation: {
    backgroundColor: STATUS_COLORS.PENDING,
  },
  pending_continue: {
    backgroundColor: STATUS_COLORS.PENDING,
  },
  sended_accepted: {
    backgroundColor: STATUS_COLORS.FINISHED,
  },
  sended_denied: {
    backgroundColor: STATUS_COLORS.CANCELED,
  },
  finished: {
    backgroundColor: STATUS_COLORS.FINISHED, 
  },
  pending_validation: {
    backgroundColor: STATUS_COLORS.PENDING, 
  },
}));

const Results = ({
  demands,
  roles,
  page,
  setPage,
  pageSize,
  setPageSize,
  resetPage,
  sort,
  setSort,
  isLoading,
  refetchDemands,
}) => {
  const { t } = useTranslation('demands');
  const classes = useStyles();
  const isSuperAdmin = useSelector(isSuperAdminSelector);
  const isAdmin = useSelector(isAdminSelector);

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleSortChange = (order) => {
    resetPage();

    if (sort === order) {
      setSort(`-${order}`);
    } else {
      setSort(order);
    }
  };

  const getZones = (demand) => {
    const prescriptorZone = demand.centerPrescriptor?.province?.name;
    const realizatorZone = demand.centerRealizator?.province?.name;

    if (!prescriptorZone && !realizatorZone) {
      return '-';
    } else if (prescriptorZone && realizatorZone) {
      if (prescriptorZone === realizatorZone) {
        return prescriptorZone;
      } else {
        return `${demand.centerPrescriptor?.province?.name} - ${demand.centerRealizator?.province?.name}`;
      }
    } else {
      return prescriptorZone || realizatorZone;
    }
  };

  return (
    <Card>
      <PerfectScrollbar>
        <Box>
          <Table className={classes.table_list}>
            <TableHead>
              <TableRow>
                <TableSortCell handler={handleSortChange} sort={sort} sortKey="reference">
                  {t('fields.reference')}
                </TableSortCell>
                <TableSortCell handler={handleSortChange} sort={sort} sortKey="doctor.name">
                  {t('fields.doctor')}
                </TableSortCell>
                <TableSortCell handler={handleSortChange} sort={sort} sortKey="createdAt">
                  {t('fields.demandDate')}
                </TableSortCell>
                <TableCell>{t('fields.treatmentStartCounter')}</TableCell>
                <TableSortCell handler={handleSortChange} sort={sort} sortKey="lastDemand">
                  {t('fields.lastDemand')}
                </TableSortCell>
                <TableCell>{t('fields.lastDemandCounter')}</TableCell>
                {(isSuperAdmin || isAdmin) && (
                  <TableSortCell handler={handleSortChange} sort={sort} sortKey="company.name">
                    {t('fields.company')}
                  </TableSortCell>
                )}
                <TableSortCell
                  handler={handleSortChange}
                  sort={sort}
                  sortKey="centerPrescriptor.name"
                >
                  {t('fields.centerPrescriptor')}
                </TableSortCell>
                <TableCell>{t('fields.provinces')}</TableCell>
                <TableSortCell handler={handleSortChange} sort={sort} sortKey="patient.cardNumber">
                  {t('fields.patientCard')}
                </TableSortCell>
                <TableSortCell handler={handleSortChange} sort={sort} sortKey="type">
                  {t('fields.demandType')}
                </TableSortCell>
                <TableCell>{t('fields.status')}</TableCell>
                <TableCell align="right">{t('fields.actions')}</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {demands?.rows.map((demand) => {
                const status = getDemandStatus(
                  demand,
                  roles?.find(({ id }) => id === demand.assignedRoleId)?.permissions,
                );
                return (
                  <TableRow hover key={demand.id}>
                    <TableCell>{demand.reference}</TableCell>
                    <TableCell>{demand.doctor?.name}</TableCell>
                    <TableCell>
                      {demand.createdAt && format(new Date(demand.createdAt), 'dd/MM/yyyy')}
                    </TableCell>
                    <TableCell 
                      title={demand.tratamientoRadioterapico ? 
                        (t('fields.treatmentStartDate') + (
                          demand.radiotherapyTreatments.length > 0 && demand.radiotherapyTreatments[0].fechaInicio ? 
                              format(new Date(demand.radiotherapyTreatments[0].fechaInicio), 'dd/MM/yyyy')
                              : t('fields.none')
                          )
                        )
                      : (t('fields.sessionFirstDate') + (
                          demand.quimioterapicoFechaPrimeraSesion ? 
                              format(new Date(demand.quimioterapicoFechaPrimeraSesion), 'dd/MM/yyyy')
                              : t('fields.none')
                          )
                        ) } >
                      {
                        demand.tratamientoRadioterapico ? 
                        (demand.radiotherapyTreatments.length > 0 && demand.radiotherapyTreatments[0].fechaInicio ? 
                            differenceInBusinessDays(new Date(demand.radiotherapyTreatments[0].fechaInicio), new Date())
                            : '-') 
                        : 
                        (demand.quimioterapicoFechaPrimeraSesion ? 
                          differenceInBusinessDays(new Date(demand.quimioterapicoFechaPrimeraSesion), new Date()) 
                          : '-')
                      }
                    </TableCell>
                    <TableCell>
                      {demand.lastDemand &&
                        format(new Date(demand.lastDemand), 'dd/MM/yyyy HH:mm:ss')}
                    </TableCell>
                    <TableCell>
                      {demand.lastDemand ? differenceInBusinessDays(new Date(), new Date(demand.lastDemand)) : null}
                    </TableCell>
                    {(isSuperAdmin || isAdmin) && <TableCell>{demand.company?.name}</TableCell>}
                    <TableCell>{demand.centerPrescriptor?.name}</TableCell>
                    <TableCell>{getZones(demand)}</TableCell>
                    <TableCell>{demand.patient?.cardNumber}</TableCell>
                    <TableCell>
                      {t(getLocationTranslation(demand.type)) || t('labels.not_defined')}
                    </TableCell>
                    <TableCell>
                      {demand.status && (
                        <Typography
                          variant="body2"
                          align="center"
                          className={`${classes.status} ${classes[status]}`}
                        >
                          {t(`status.${status}`)}
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell align="right">
                      <DemandsActions
                        demand={demand}
                        roles={roles}
                        isLoading={isLoading}
                        refetch={refetchDemands}
                        listActions={true}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>

          {!isLoading && !demands?.rows.length && (
            <EmptyList text={t('labels.empty_list_text')}>
              <DemandsIcon />
            </EmptyList>
          )}
        </Box>
      </PerfectScrollbar>

      <TablePagination
        component="div"
        count={demands?.count || 0}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePageSizeChange}
        page={demands?.count ? page - 1 : 0}
        rowsPerPage={pageSize}
        rowsPerPageOptions={[25, 50, 100]}
      />
    </Card>
  );
};

Results.propTypes = {
  demands: PropTypes.object,
  roles: PropTypes.array,
  page: PropTypes.number,
  setPage: PropTypes.func,
  pageSize: PropTypes.number,
  setPageSize: PropTypes.func,
  resetPage: PropTypes.func,
  sort: PropTypes.string,
  setSort: PropTypes.func,
  isLoading: PropTypes.bool,
  deleteDemand: PropTypes.func,
  refetchDemands: PropTypes.func,
};

export default Results;
